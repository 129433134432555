import React from 'react';
import {Image} from "primereact/image";
import logo from "./logo.svg";
import {Menubar} from "primereact/menubar";
import {useAuth0} from "@auth0/auth0-react";
import {MenuItem, MenuItemCommandEvent} from "primereact/menuitem";

export const MainMenu = () => {
    const {user, isAuthenticated, loginWithRedirect, logout} = useAuth0();

    let menuItems :MenuItem[] = [];
    if(!isAuthenticated) {
        menuItems = [{
            id: "login",
            label: "Log in",
            command : (event: MenuItemCommandEvent) => {
                return loginWithRedirect();
            }
        }]
    } else {
        menuItems = [{
            id: "username",
            label: user?.nickname || "User",
            items: [{
                id: "logout",
                label: "Log out",
                command: (event: MenuItemCommandEvent) => {
                    return logout(
                        {
                            logoutParams: {
                                returnTo: window.location.origin
                            }
                        }
                    );
                }
            }]
        }]
    }
    return (<div className={"grid"}>
        <Menubar model={menuItems} className={"col"} start={<Image src={logo}/>}/>
    </div>)
}