import React, {useRef, useState} from 'react';
import {DateTime} from "luxon";
import ReactMarkdown from 'react-markdown'
import 'primeicons/primeicons.css';
import {Avatar} from "primereact/avatar";
import {Divider} from "primereact/divider";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {Formik, useFormik} from "formik";
import {gql} from "./__generated__";
import {useMutation} from "@apollo/client";
import {Toast} from "primereact/toast";
import {useAuth0} from "@auth0/auth0-react";

export type PostParameters = {
    authorUsername : string
    authorDisplayName : string
    authorDisplayPicture : string
    created : DateTime
    body: string
    divider? : boolean
}

export type PostFormParameters = {
    onPosted?: () => Promise<any>
}

type PostFormValues = {
    post: string
}

const GQL_CREATE_POST = gql(`mutation createPost($username : String!, $body : String!) {
    createPost(input: { username: $username, body: $body }) {
        post {
            postID
        }
    }    
}`)

export const PostForm = (p : PostFormParameters) => {
    const initialValues : PostFormValues = {post: ''};
    const {user} = useAuth0();
    const [createPost] = useMutation(GQL_CREATE_POST);
    const toast = useRef<Toast>(null);

    const validate = ({post} : PostFormValues) => {
        let errors: {post? : string} = {};
        if(post.trim().length === 0) {
            errors.post = "post is empty"
        }
        return errors
    }

    const onSubmit = ({post} : PostFormValues) => {
        console.log(user);
        const username = user?.["https://blks.qux.rs/username"] || "";
        if(!username) {
            toast.current?.show({
                severity: "error",
                summary: "Not signed in",
                detail: "Please sign in to post bollocks!"
            })
            return;
        }
        createPost({
            variables: {
                username,
                body: post,
            }
        }).then(() => formik.resetForm()).then(() => {
            toast.current?.show({
                severity: "success",
                summary: "Bollocks posted",
                detail: "Your bollock has been dropped"
            })
            if(p.onPosted) {
                return p.onPosted()
            }
        }).catch((e) => {
            toast.current?.show({
                severity: "error",
                summary: "Oh balls!",
                detail: "There was an error. Sorry!"
            })
            console.log(e);
        });
    }

    const formik= useFormik<PostFormValues>({
        initialValues,
        validate,
        onSubmit,
        isInitialValid: false,
    });

    return (
        <div>
            <Toast ref={toast} />
            <div className={"grid p-inputgroup"}>
                <span className="p-inputgroup-addon">
                    <i className="pi pi-pencil"></i>
                </span>
                <InputTextarea placeholder="Drop a bollock" onChange={(e) => {
                    return formik.setFieldValue('post', e.target.value);
                }} value={formik.values.post}/>
                <Button icon="pi pi-send" className="p-button-warning"
                        disabled={!formik.isValid} onClick={formik.submitForm}/>
            </div>
        </div>
    );
}
export const Post = ({authorDisplayPicture, authorDisplayName, authorUsername, created, body, divider = false} : PostParameters) => {
    // +---------------------------------+
    // | +----+ Username • 2023...       |
    // | |    |   Post Body              |
    // | +----+                          |
    // +---------------------------------+
    return (<div>
        {divider ? <Divider/> : "" }
        <div className={"grid"}>
            <div className={"col-2 post-author"}>
                {authorDisplayName || authorUsername}
            </div>
            <div className={"col text-right post-timestamp"}>
                {created.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
            </div>
        </div>
        <div className={"grid"}>
        <Avatar size={'xlarge'} image={authorDisplayPicture} imageAlt={authorDisplayName+"'s display picture"} className={"col-2"}/>
        <ReactMarkdown children={body} className={"col"}/>
        </div>
    </div>)
}