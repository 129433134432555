/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date is an RFC3339 formatted date string */
  Date: { input: any; output: any; }
};

/** CreatePostInput provides inputs to publish a post on behalf of a user */
export type CreatePostInput = {
  /** body is the string content of the post */
  body: Scalars['String']['input'];
  /** username is the username of the author */
  username: Scalars['String']['input'];
};

export type CreatePostOutput = {
  __typename?: 'CreatePostOutput';
  post: Post;
};

/** CreateUserProfileInput provides inputs to populate a user's profile. */
export type CreateUserProfileInput = {
  /** bio is the biography of the user */
  bio: Scalars['String']['input'];
  /** displayName is the human-readable name that should be displayed in UIs. this could be their full name */
  displayName: Scalars['String']['input'];
  /** userid is the unique identifier from our identity provider */
  userid: Scalars['ID']['input'];
  /** username is the unique human-readable name for this user. it is used in URLs etc */
  username: Scalars['String']['input'];
};

export type CreateUserProfileOutput = {
  __typename?: 'CreateUserProfileOutput';
  username: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** createPost publishes a post for a user */
  createPost: CreatePostOutput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};

/** Data about an individual post */
export type Post = {
  __typename?: 'Post';
  /** the user account that created the post */
  author: ShortUserProfile;
  /** the body of the post */
  body: Scalars['String']['output'];
  /** the RFC3339 date the post was created */
  created: Scalars['Date']['output'];
  /** an identifier for this post, which can be used in calls back to the API */
  postID: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  feed: Array<Post>;
  userProfile?: Maybe<UserProfile>;
};


export type QueryFeedArgs = {
  afterPostID?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserProfileArgs = {
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ShortUserProfile = {
  __typename?: 'ShortUserProfile';
  displayName: Scalars['String']['output'];
  displayPicture?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

/** UserProfile provides details about a particular user. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** bio the user's biography */
  bio: Scalars['String']['output'];
  /** displayName is the human-readable name that should be displayed in UIs. this could be their full name */
  displayName: Scalars['String']['output'];
  /** posts returns the user's posts in descending time order */
  posts: Array<Post>;
  /** userid is the unique identifier from our identity provider */
  userid: Scalars['ID']['output'];
  /** username is the unique human-readable name for this user. it is used in URLs etc */
  username: Scalars['String']['output'];
};


/** UserProfile provides details about a particular user. */
export type UserProfilePostsArgs = {
  afterPostID?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};

export type QQueryVariables = Exact<{ [key: string]: never; }>;


export type QQuery = { __typename?: 'Query', feed: Array<{ __typename?: 'Post', postID: string, created: any, body: string, author: { __typename?: 'ShortUserProfile', username: string, displayName: string, displayPicture?: string | null } }> };

export type CreatePostMutationVariables = Exact<{
  username: Scalars['String']['input'];
  body: Scalars['String']['input'];
}>;


export type CreatePostMutation = { __typename?: 'Mutation', createPost: { __typename?: 'CreatePostOutput', post: { __typename?: 'Post', postID: string } } };


export const QDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Q"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"feed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"IntValue","value":"100"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"postID"}},{"kind":"Field","name":{"kind":"Name","value":"author"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"displayPicture"}}]}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"body"}}]}}]}}]} as unknown as DocumentNode<QQuery, QQueryVariables>;
export const CreatePostDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createPost"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"username"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"body"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPost"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"username"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"body"},"value":{"kind":"Variable","name":{"kind":"Name","value":"body"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"post"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"postID"}}]}}]}}]}}]} as unknown as DocumentNode<CreatePostMutation, CreatePostMutationVariables>;