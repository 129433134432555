import React, {useState} from 'react';
import './App.css';
import {Panel} from "primereact/panel";
import {MainMenu} from "./MainMenu";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {Auth0Domain, Auth0ClientID, APIUri, Auth0Audience, Auth0Scopes} from "./config";
import {Feed} from "./Feed";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

function App() {


    return (
        <Auth0Provider
          domain={Auth0Domain}
          clientId={Auth0ClientID}
          authorizationParams={{
              redirect_uri: window.location.origin,
              scope: Auth0Scopes.join(" "),
                audience: Auth0Audience
          }}
        >
          <Main/>
        </Auth0Provider>
    );
}

const Main = () => {
    const httpLink = createHttpLink({
        uri: APIUri,
    });
    const {isAuthenticated, getAccessTokenSilently, logout} = useAuth0();
    const [accessToken, setAccessToken] = useState("");

    if(isAuthenticated) {
        getAccessTokenSilently({
            authorizationParams: {
                audience: Auth0Audience,
                scope: Auth0Scopes.join(" "),
            }
        }).then((tok) => {
            console.log("token: ", tok)
            setAccessToken(tok);
        }).catch((ex) => {
            console.error(ex);
            return logout();
        })
    }

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: accessToken ? `Bearer ${accessToken}` : "",
            }
        }
    });
    const apolloClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink)
    });

    return (
        <ApolloProvider client={apolloClient}>
            <MainMenu />
            <div className={"grid"}>
                <Panel className={"col-6 col-offset-3 gap-5"}>
                    <Feed />
                </Panel>
            </div>
        </ApolloProvider>
    );
}

export default App;
