import React from 'react';
import {Post, PostForm} from "./Post";
import {DateTime} from "luxon";
import {useAuth0} from "@auth0/auth0-react";
import {gql} from "./__generated__";
import {useQuery} from "@apollo/client"
import { ProgressSpinner } from 'primereact/progressspinner';
import dp1 from './assets/profile1-dp.png';

const GQL_GET_FEED_ITEMS = gql(`
    query Q {
    feed(limit: 100) {
        postID,
            author{
            username,
                displayName,
                displayPicture
        },
        created,
            body,
    }
}
`);

export const Feed = () => {
    const {isAuthenticated} = useAuth0();
    const {loading, data, refetch} = useQuery(
        GQL_GET_FEED_ITEMS,
    )
    if(loading) {
        return <ProgressSpinner />
    } else {

        let out = isAuthenticated ? [<PostForm  onPosted={() => {
            return refetch();
        }}/> ] : [];

        return (<div>
            {
                out.concat(data?.feed.map((x) => {
                    return <Post key={x.postID} authorDisplayPicture={x.author.displayPicture || dp1} authorUsername={x.author.username} authorDisplayName={x.author.displayName} created={DateTime.fromISO(x.created)} body={x.body} divider={true}/>
                }) || [])
            }
        </div>)
    }
}